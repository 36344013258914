import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo } from "components/common"
import { device, useMeta } from "utils"

import ApplyWave from "src/components/sections/apply/ApplyWave.js"

const IndexPage = ({ data: { apply } }) => {
  const meta = useMeta(apply)

  const section = apply.sections[0]

  return (
    <Layout hideFooter>
      <Seo {...meta} />
      <Wrap>
        <ApplyWave {...section} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

export const query = graphql`
  {
    apply: contentfulPage(pageId: { eq: "apply" }) {
      ...Page
    }
  }
`
export default IndexPage
