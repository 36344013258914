import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"

import styled from "styled-components"
import PlayShowReel from "src/components/animations/PlayShowReel"

import PrismButton from "src/components/common/PrismButton"
import { Nav, TextGroup } from "src/components/common"
import { device } from "src/utils"

import { homeThemeState } from "src/atoms"
import { useRecoilState } from "recoil"

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.6,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: -20 },
  show: { opacity: 1, y: 0 },
}

const HomeWave = ({ hat, title, subtitle }) => {
  const [theme, setTheme] = useRecoilState(homeThemeState)

  const [formState, setState] = useState({
    email: "",
    name: "",
  })

  useEffect(() => {
    setTheme("lab")

    return () => setTheme("design")
  }, [])

  return (
    <Background data-theme={theme}>
      <Nav dark={true} />
      <StyledHomeWave>
        <motion.div
          className="animated-container"
          variants={container}
          initial="hidden"
          animate="show"
          key={theme}
        >
          <div className="spacer" />
          <div className="container">
            <TextGroup
              hat={hat}
              title={title}
              titleAs="h1"
              subtitle={subtitle}
            />

            <form className="form">
              <input
                type="text"
                value={formState.name}
                onChange={(e) =>
                  setState((state) => ({
                    ...state,
                    name: e.target.value,
                  }))
                }
              />
              <input
                type="email"
                value={formState.email}
                onChange={(e) =>
                  setState((state) => ({
                    ...state,
                    email: e.target.value,
                  }))
                }
              />
              <button type="submit">Apply Here</button>
            </form>
          </div>

          <motion.div
            className="bottom"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {!formState.email && !formState.name && (
              <PrismButton
                ctaText="Apply here"
                ctaHref="mailto:weston@secretsauce.design?subject=Secret Sauce Application"
              />
            )}
          </motion.div>
        </motion.div>
        <motion.div className="showWrap" variants={item}>
          <PlayShowReel />
        </motion.div>
      </StyledHomeWave>
    </Background>
  )
}

const Background = styled.section`
  --wave-background: black;
  --title-color: #ffffff;
  --values-color: #ababab;
  --toggle-color-active: #181818;
  --toggle-color-inactive: #676767;
  --toggle-background-active: #fff;

  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background: var(--wave-background);
  height: 100%;
  overflow: hidden;
`

const StyledHomeWave = styled(motion.div)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  flex: 1;

  padding: 20vh 8px 0px;
  @media ${device.laptop} {
    padding: 30vh 16px 0px;
  }

  .container {
    .form {
      position: absolute;
      z-index: -99;
      visibility: hidden;
    }

    .textContainer {
      color: white;
      text-align: center;
      opacity: 1;
      /* max-width: 900px; */
      margin: 0 auto;

      .title {
        text-align: center;
        margin: 0 auto;
        margin-top: var(--sp-16);
        color: var(--title-color);
      }

      h5 {
        color: white;
      }

      p.subtitle {
        max-width: 800px;
        color: #ababab;

        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .animated-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    @media ${device.laptop} {
      padding-top: 0;
    }
  }

  .bottom {
    padding: 0 var(--sp-16);
    margin: 0 auto;
    margin-top: var(--sp-48);
  }

  .buttonB {
    /* height: 60px;
      width: 200px; */

    height: 100px;
    width: 300px;
    padding: 0;
  }

  .container {
    position: relative;
    z-index: 5;
  }

  .toggle {
    display: flex;
    justify-content: center;
    grid-gap: var(--sp-8);

    @media ${device.mobileM} {
      grid-gap: var(--sp-16);
    }

    @media ${device.laptop} {
      grid-gap: var(--sp-24);
    }

    button {
      color: var(--toggle-color-inactive);
      font-family: "PP Mori";
      cursor: pointer;
      position: relative;
      padding: 12px;

      overflow: hidden;

      span {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: -0.02em;
        position: relative;
        z-index: 2;
        line-height: 1;
      }

      &::before {
        content: "";
        background: var(--toggle-background-active);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 1;
        transition-property: width;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        width: 0%;
      }
      &.active {
        color: var(--toggle-color-active);
        &::before {
          width: 100%;
        }
      }

      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }

  .showWrap {
    position: absolute;
    right: 60px;
    bottom: 40px;
    z-index: 2;

    display: none;

    @media ${device.laptop} {
      display: inline-block;
    }
  }
`

export default HomeWave
